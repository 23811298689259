/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-item:last-of-type::part(native) {
  margin-bottom: -1px !important;
}

form,
ion-item-sliding {
  ion-item:last-of-type::part(native) {
    margin-bottom: 0 !important;
  }

  &:last-of-type ion-item::part(native) {
    //margin-bottom: -1px !important;
  }
}

ion-item[appearance="fill"]::part(native) {
  background-color: rgba(0, 0, 0, .1) !important;
}

.ion-select-popover-full {
  .popover-content {
    width: calc(100% - 32px) !important;
    left: 16px !important;

    .popover-viewport {
      & > * {
        margin: 0 !important;
      }

      ion-item:last-of-type::part(native) {
        margin-bottom: -1px !important;
      }
    }
  }

  &.ion-select-group {
    ion-item.item-interactive-disabled {
      padding-top: 16px;
      margin-bottom: -1px !important;

      &::part(native) {
        min-height: unset !important;
        max-height: fit-content !important;
      }

      &:not(:first-child) {
        margin-top: -1px !important;
      }

      & ~ ion-item:not(.item-interactive-disabled) {
        margin-left: 16px !important;
      }

      ion-label {
        opacity: .87 !important;
        font-weight: bold !important;
      }

      ion-radio {
        display: none !important;
      }
    }
  }
}

.ion-select-wrapper-full {
  .alert-wrapper {
    max-width: calc(100% - 32px) !important;
    width: 100% !important;

    .alert-radio-group {
      box-sizing: content-box !important;
    }
  }
}

.ion-close-icon::part(native) {
  box-shadow: none !important;
  border: none !important;
}

ion-button[ion-icon] {
  margin: 0;
  box-sizing: content-box;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  overflow: hidden;

  &::part(native) {
    padding: 8px;
  }

  &.no-padding {
    &::part(native) {
      padding: 0;
    }
  }
}

.swiper-static .swiper-pagination {
  position: static;
  margin-top: 16px;
}

ion-item.ion-no-padding::part(native) {
  --inner-padding-end: 0;
}

@media only screen and (min-width: 768px){
  html {
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  body {
    margin-top: 64px;
    position: relative;
    width: 411px;
    height: 731px;
    box-sizing: content-box;
    border: 1px solid rgba(0,0,0,.21);
  }

  .popover-content {
    margin-top: -64px !important;
  }
}
